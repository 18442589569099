@import '@aws-amplify/ui-react/styles.css';
@import 'buildinfo.scss';

$white: white;
$white2: #EFF0F0;
$black: black;
$red: #ff3030;
$darkWhite: #fafafa;
$lightGray: #e4e4e4;
$darkGray: #919191;
$darkGray2: #4e5153;
$lightPink: #ffe9e9;

html,
body {
  padding: 0;
  margin: 0;
}
:root {
  --amplify-components-fieldcontrol-error-border-color: #{$red};
  --amplify-components-fieldmessages-error-color:  #{$red};
  --amplify-components-fieldcontrol-error-focus-box-shadow:  #{$red};
  --amplify-components-checkbox-button-error-border-color: #{$red};
  --amplify-components-fieldcontrol-padding-block-start: 5px;
  --amplify-components-fieldcontrol-padding-block-end: 5px;
}

.form-control  {
  box-sizing: border-box;
  color: var(--amplify-components-fieldcontrol-color) !important;
  font-size: var(--amplify-components-fieldcontrol-font-size) !important;
  line-height: var(--amplify-components-fieldcontrol-line-height) !important;
  padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start) !important;
  padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end) !important;
  padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start) !important;
  padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end) !important;
  transition: all var(--amplify-components-fieldcontrol-transition-duration) !important;
  width: 100% !important;
  border-color: var(--amplify-components-fieldcontrol-border-color) !important;
  border-radius: 10px !important;
  height: 36px !important;
  border-style: var(--amplify-components-fieldcontrol-border-style) !important;
  border-width: var(--amplify-components-fieldcontrol-border-width) !important;
  outline-color: var(--amplify-components-fieldcontrol-outline-color) !important;
  outline-style: var(--amplify-components-fieldcontrol-outline-style) !important;
  outline-width: var(--amplify-components-fieldcontrol-outline-width) !important;
  outline-offset: var(--amplify-components-fieldcontrol-outline-offset) !important;
  gap: var(--amplify-components-field-gap) !important;
  padding-left: 43px !important;
}

.form-control:disabled, .amplify-input[disabled] {
  color: $darkGray2 !important;
  cursor: var(--amplify-components-fieldcontrol-disabled-cursor) !important;
  border-color: var(--amplify-components-fieldcontrol-disabled-border-color) !important;
  background: $white2 !important;
  opacity: 1;
}

.flag-dropdown {
  margin: 2px;
  border-radius: 8px 0 0 8px !important;
}

.selected-flag {
  border-radius: 8px 0 0 8px !important;
}

.amplify-radio__input:checked + .amplify-radio__button {
  color: $black !important;
}

.amplify-radio__input:focus + .amplify-radio__button {
  border-color: $black !important;
}

.amplify-checkbox__icon {
  background-color: $black !important;
}

.form-control:disabled + .flag-dropdown {
  background-color:  var(--amplify-components-fieldcontrol-disabled-background-color) !important;
  border: none;
}

.amplify-alert--error {
  color: $red !important;
  background-color: $lightPink !important;
}

.amplify-field__error-message {
  line-height: 1;
  @media(min-width: 576px) {
    position: absolute;
    top: 100%;
  }
}

.amplify-field {
  gap: 3px;
  @media(min-width: 576px) {
    margin-bottom: 16px;
    position: relative;
  }
}

.age-input {
  .amplify-field {
    @media(min-width: 576px) {
      margin-bottom: 30px;
    }
  }
}

.phone-with-error input {
  border-color: var(--amplify-components-fieldcontrol-error-border-color) !important;
}

.custom-button {
  width: 100%;
  border-radius: 30px;
  font-weight: 500;
  border-color: $darkWhite;

  &:hover, &:active, &:focus {
    color: $darkWhite;
    border-color: $darkWhite;
  }

  @media(min-width: 576px) {
    &:hover, &:active {
      background: $black;
      border-color: $darkWhite;
      color: $darkWhite;
    }
  
    &:focus {
      border-color: 'transparent';
      box-shadow: 'none';
    }
  }
}


.custom-button-primary {
  background: $darkWhite;

  &:focus, &:hover {
    color: $black;
    background: $darkWhite;
    box-shadow: none;
  }

  &:active {
    background: $black;
    border-color: $black;
    color: $darkWhite;;
  }
  

  @media (hover: hover) {
    &:hover {
      background: $black;
      border-color: $black;
      color: $darkWhite;;
    }
  }
}

.custom-button-secondary {
  background: linear-gradient(180deg, #62BF97 0%, #239564 100%);
  color: $white;

  &.amplify-button--loading {
    background: linear-gradient(180deg, #62BF97 0%, #239564 100%) !important;
    color: $white !important;
  }
}

.custom-button-dark {
  background: $black;
  color: $white;
  @media(min-width: 576px) {
    &:hover, &:active {
      background: $white;
      border-color: $black;
      color: $black;
    }
  }
}

.custom-button-error {
  border: 'none';
  background: 'transparent';
  color: $red;
  @media(min-width: 576px) {
    &:hover, &:active {
      background: $red;
      border-color: $red;
      color: $white;
    }
  }
}

.custom-button-disable {
  background: $lightGray !important;
  color: $darkGray !important;
}

.react-tel-input {
  font-family: inherit !important;
}

.amplify-table__td, .amplify-table__th {
  @media(max-width: 576px) {
    font-size: 12px !important;
    padding: 10px;
  }
}

[data-amplify-authenticator][data-variation=modal] {
  &:after {
    content: $buildinfo;
    position: absolute;
    right: 20px;
    bottom: 15px;
  }
}

.webpage-version {
  &:after {
    content: $buildinfo;
  }
}

.amplify-passwordfield {
  flex-direction: row;
}

.amplify-passwordfield .amplify-field-group {
  width: 100%;
}

.amplify-passwordfield::after {
  content: 'ⓘ';
  font-size: 1.4em;
  padding-left: .4em;
}

.amplify-passwordfield:hover .amplify-field-group:not(:hover)::after {
  content: 'A passphrase is like a password, but longer and with multiple words';

  display: block;
  position: absolute;
  right: -150px;
  width: 300px;
  top: -60px;

  background: $black;
  color: $white;
  border-radius: 3px;
  padding: 5px 10px;
}
